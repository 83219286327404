import { HttpErrorResponse } from '@angular/common/http';
import { APIError } from '@core/http/errors';

declare global {
  interface NewRelic {
    noticeError(error: unknown): void;
  }

  interface Window {
    newrelic?: NewRelic;
  }
}

export const HandledError = Symbol('HandledError');

export function logError(err: unknown): void {
  console.error(err);
  if (!err || err instanceof APIError || err instanceof HttpErrorResponse) {
    return;
  }
  setTimeout(() => {
    window.newrelic?.noticeError(err);
    throw Object.assign(err, { [HandledError]: true });
  }, 0);
}
